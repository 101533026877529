import Background from '../../imgs/bg.gif';
import Back from '../../imgs/backarrow.gif';
import Hat from '../../imgs/hat.gif';
import Hot from '../../imgs/hot.gif'
import Earth from '../../imgs/earth.gif'
import Email from '../../imgs/email.gif'
import '../../App.css';

import { Link } from "react-router-dom";

function Urbex() {
  return (
    <div className="App" style={{
      backgroundImage: `url(${Background})`
    }}>
      <div className="Content">
        <h1>2000</h1>
        <h3>A collection of hyperlinks, today.</h3>
		<Link to='/'><img src={Back} alt="Back Home" /></Link>
		<br />
		<h2>Urbex</h2>
		<img src={Hat} alt="" />
        <ul>
          <li><a href='https://shhsydney.wordpress.com/'>ShhSydney</a></li>
		  <li><a href='https://www.uega.net/forums/index.php'>Urban Exploration Group Australia Forums</a><img class="hot" alt="Hot" src={Hot} /></li>
		  <li><a href='https://social.shorthand.com/honi_soit/32OvqLamxe/the-tunnel-club'>An article on The Tunnel Club</a></li>
		  <li><a href='https://www.uega.net/forums/viewtopic.php?f=8&t=5544'>Hercules' Pillars</a></li>
		  <li><a href='https://www.uega.net/forums/viewtopic.php?f=8&t=97'>THE MASTER LIST!!!!</a><img class="hot" alt="Hot" src={Hot} /></li>
		  <li><a href='https://www.visitsydneyaustralia.com.au/lost-tunnels.html'>Lost Tunnels</a></li>
		  <li><a href='https://en.wikipedia.org/wiki/Signal_Hill_Battery'>Signal Hill Battery, Vaucluse</a></li>
		</ul>

        <img class="earth" src={Earth} alt="" />
        <div class="footer">
          Copyright &copy; tomlister 2021<br />
          Contact the webmaster <a href="mailto:tom@tomlister.net">tom@tomlister.net</a>
          <img src={Email} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Urbex;
