import Background from './imgs/bg.gif';
import New from './imgs/new.gif';
import Fire from './imgs/uc.gif';
import Construction from './imgs/construction.gif'
import Earth from './imgs/earth.gif'
import Email from './imgs/email.gif'
import './App.css';

import { Link } from "react-router-dom";

function App() {
  return (
    <div className="App" style={{
      backgroundImage: `url(${Background})`
    }}>
      <div className="Content">
        <h1>Welcome to 2000</h1>
        <h3>A collection of hyperlinks, today.</h3>
        <ul>
          <li><Link to='/urbex'>urbex</Link> <img class="new" alt="new" src={New} /></li>
          <li>more coming soon...</li>
        </ul>
        <br />
        <img src={Construction} alt="" />
        <br />
        <img src={Fire} alt="" />
        <p>under construction 4eva!!</p>
        <img class="earth" src={Earth} alt="" />
        <div class="footer">
          Copyright &copy; tomlister 2021<br />
          Contact the webmaster <a href="mailto:tom@tomlister.net">tom@tomlister.net</a>
          <img src={Email} alt="" />
        </div>
      </div>
    </div>
  );
}

export default App;
